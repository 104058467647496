import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  // Fade,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { noop } from 'lodash/fp';

const useStyles = makeStyles((theme) => ({
  giveBackBox: {
    minWidth: '50px',
    minHeight: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    borderTopLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  card: {
    position: 'relative',
    borderRadius: '16px',
  },
  cardMedia: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 'calc(56.25% + 19px)',
    backgroundSize: 'contain',
    borderRadius: '16px',
  },
  standAloneCard: {
    width: '100%',
    paddingTop: 'calc(56.25% + 18px)',
    backgroundSize: 'contain',
  },
  square: {
    borderRadius: '0',
  },
}));

const CustomImgCard = ({
  name,
  imageUrl,
  giveBack,
  details,
  elevation,
  variant,
  onClick,
  clickable,
  square,
  width,
  isLoading,
  ariaLabel,
}) => {
  const classes = useStyles();
  const giveBackPercent = `${parseFloat((giveBack * 100).toFixed(2))}%`;

  return (
    <>
      <Card
        variant={variant}
        square={square}
        elevation={elevation}
        className={square ? classes.square : classes.card}
      >
        {giveBack ? (
          <Box className={classes.giveBackBox}>
            <Typography display="block" align="center" variant="subtitle1">
              {giveBackPercent}
            </Typography>
          </Box>
        ) : null}
        {clickable ? (
          <>
            {!imageUrl || isLoading ? (
              <Skeleton variant="rect" className={classes.cardMedia} />
            ) : (
              <CardActionArea aria-label={ariaLabel} onClick={onClick}>
                <CardMedia
                  className={classes.cardMedia}
                  alt={name}
                  image={imageUrl}
                />
              </CardActionArea>
            )}
          </>
        ) : (
          <>
            {!imageUrl || isLoading ? (
              <Skeleton
                variant="rect"
                className={
                  square
                    ? `${classes.standAloneCard} ${classes.square}`
                    : classes.standAloneCard
                }
                style={width ? { width: `${width}` } : {}}
              />
            ) : (
              <CardMedia
                className={
                  square
                    ? `${classes.standAloneCard} ${classes.square}`
                    : classes.standAloneCard
                }
                alt={name}
                image={imageUrl}
                style={width ? { width: `${width}` } : {}}
              />
            )}
          </>
        )}
      </Card>
      {details && (
        <Typography
          display="block"
          align="center"
          variant="body1"
          color="primary"
        >
          {!name || isLoading ? <Skeleton /> : name}
        </Typography>
      )}
    </>
  );
};

CustomImgCard.defaultProps = {
  name: '',
  imageUrl: '',
  giveBack: 0,
  details: false,
  elevation: 3,
  clickable: false,
  variant: 'outlined',
  onClick: noop,
  square: false,
  width: '',
  isLoading: false,
  ariaLabel: 'clickable image',
};

CustomImgCard.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  variant: PropTypes.string,
  giveBack: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.bool,
  elevation: PropTypes.number,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  square: PropTypes.bool,
  width: PropTypes.string,
  isLoading: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default CustomImgCard;

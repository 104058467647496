import api from '../../utils/apiFetch';

const path = 'organizations';

export default {
  getOrgDetails: (user) => api.get(`${path}/info`, user),
  getOrgPayouts: (user) => api.get(`${path}/payouts`, user),
  updateAddress: (payload, user) => api.post(`${path}/address`, payload, user),
  updateBankDetails: (payload, user) =>
    api.post(`${path}/banking`, payload, user),
};

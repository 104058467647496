import { getOr } from 'lodash/fp';

const baseUrl = `${process.env.GATSBY_API_URL}`;

const authHeader = (user) => {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = getOr(null, 'jwtToken', user);
  const isLoggedIn = user && token;
  //   const isApiUrl = url.startsWith(process.env.GATSBY_API_URL);
  if (isLoggedIn) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

function handleResponse(response, user) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // logout();
        // console.log('logout with 401,403');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

const get = (url, user) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(user),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
};

const post = (url, body, user) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader(user) },
    credentials: 'include',
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then((res) =>
    handleResponse(res, user)
  );
};

const put = (url, body) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
};
// prefixed with underscored because delete is a reserved word in javascript
// const del = (url) => {
//   const requestOptions = {
//     method: 'DELETE',
//     headers: authHeader(url),
//   };
//   return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
// };

const fetchWrapper = {
  get,
  post,
  put,
};

export default fetchWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';

const PreLoader = ({ forComponent }) => {
  return (
    <Grid
      item
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ minHeight: forComponent ? '100%' : 'calc(100vh - 64px)' }}
    >
      <Grid container item xs={12} justify="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

PreLoader.defaultProps = {
  forComponent: false,
};

PreLoader.propTypes = {
  forComponent: PropTypes.bool,
};

export default PreLoader;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'transactions';

const getOrgTransactions = createAsyncThunk(
  'transactions/getOrgTransactions',
  async (payload, { getState }) => {
    const user = getOr(null, ['auth', 'user'], getState());
    const response = await api.getOrgTransactions(user);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      transactions: [],
    },
    extraReducers: {
      [getOrgTransactions.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrgTransactions.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        transactions: getOr([], 'payload', action),
      }),
      [getOrgTransactions.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectOrgTransactions: (state) => getOr([], 'transactions', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getOrgTransactions,
  },
  selectors,
  reducer,
  name,
};

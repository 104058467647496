import auth from './auth';
import alert from './alert';
import org from './org';
import stats from './stats';
import payouts from './payouts';
import transactions from './transactions';

export default {
  auth: auth.reducer,
  alert: alert.reducer,
  org: org.reducer,
  stats: stats.reducer,
  payouts: payouts.reducer,
  transactions: transactions.reducer,
};

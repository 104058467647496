import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Link as MUILink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  gatsbyLink: {
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.action.active,
    },
  },
}));

// TODO Rethink this StyledLink component

const StyledLink = ({ children, to, gatsbyLink, ...rest }) => {
  const classes = useStyles();
  return gatsbyLink ? (
    <Link className={classes.gatsbyLink} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <MUILink underline="none" href={to} className={classes.gatsbyLink}>
      {children}
    </MUILink>
  );
};

StyledLink.defaultProps = {
  gatsbyLink: false,
};

StyledLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  gatsbyLink: PropTypes.bool,
};

export default StyledLink;

import React from 'react';
import PropTypes from 'prop-types';
import { getOr } from 'lodash/fp';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
  withStyles,
  TableCell,
  Paper,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

let preloadData = [];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const TransactionTable = ({
  cols,
  data,
  rowKeys,
  mods,
  isLoading,
  noDataMsg,
}) => {
  const classes = useStyles();
  React.useEffect(() => {
    preloadData = cols.map((col, i) => {
      return { id: i, [col]: null };
    });
  }, []);

  return !isLoading && data.length === 0 ? (
    <Box pt={2} width="100%" align="center">
      {noDataMsg}
    </Box>
  ) : (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="Organization Payouts">
        <TableHead>
          <TableRow>
            {cols.map((col, i) => {
              if (i === 0) {
                return (
                  <StyledTableCell key={col}>
                    {isLoading ? (
                      <Skeleton style={{ backgroundColor: '#fff' }} />
                    ) : (
                      <b>{col}</b>
                    )}
                  </StyledTableCell>
                );
              }
              return (
                <StyledTableCell key={col} align="right">
                  {isLoading ? (
                    <Skeleton style={{ backgroundColor: '#fff' }} />
                  ) : (
                    <b>{col}</b>
                  )}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? preloadData.map((datum) => (
                <StyledTableRow key={datum.id}>
                  {rowKeys.map((rowKey) => (
                    <StyledTableCell align="right" key={rowKey}>
                      {isLoading ? <Skeleton /> : datum[rowKey]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            : data.map((datum) => (
                <StyledTableRow key={datum.id}>
                  {rowKeys.map((rowKey, i) => {
                    let value = datum;
                    if (mods) {
                      const withModifier = getOr(false, `${rowKey}`, mods);
                      if (withModifier) {
                        value = {
                          ...datum,
                          [rowKey]: withModifier(datum[rowKey]),
                        };
                      }
                    }
                    if (i === 0) {
                      return (
                        <StyledTableCell key={rowKey}>
                          {isLoading ? <Skeleton /> : value[rowKey]}
                        </StyledTableCell>
                      );
                    }
                    return (
                      <StyledTableCell align="right" key={rowKey}>
                        {isLoading ? <Skeleton /> : value[rowKey]}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TransactionTable.defaultProps = {
  isLoading: false,
  data: preloadData,
  noDataMsg: 'Hmm... Looks like there is no data here.',
};

TransactionTable.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  mods: PropTypes.objectOf(PropTypes.func).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  noDataMsg: PropTypes.string,
};

export default TransactionTable;

import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    type: 'light',
    primary: {
      light: '#fff',
      main: '#000',
      dark: '#808080',
      contrastText: '#fff',
    },
    secondary: {
      light: '#eb4637',
      main: '#eb4637',
      dark: '#eb4637',
      contrastText: '#000',
    },
    background: {
      default: '#fff',
    },
  },

  typography: {
    fontFamily: 'Rubik',
  },
});

theme.overrides = {
  ...theme.overrides,
  MuiCardActionArea: {
    root: {
      height: '100%',
    },
  },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '14px',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: '14px',
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 17px) scale(1)',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '15px 14px',
    },
  },
  MuiChip: {
    root: {
      borderRadius: '4px',
      fontSize: 12,
    },
    outlined: {
      color: '#616161',
    },
  },
  MuiToggleButton: {
    root: {
      color: theme.palette.primary.main,
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  MuiCardContent: {
    root: {
      '&:last-child': {
        paddingBottom: 16,
      },
    },
  },
};

export default theme;

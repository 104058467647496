import api from '../../utils/apiFetch';

const path = 'accounts';

export default {
  login: (payload) => api.post(`${path}/authenticate`, payload),
  refreshToken: (user) => api.post(`${path}/refresh-token`, {}, user),
  logout: (user) => api.post(`${path}/revoke-token`, {}, user),
  register: (payload) => api.post(`auth/register`, payload),
  resendCode: (payload) => api.post(`auth/resend`, payload),
  forgotPassword: (payload) => api.post(`auth/forgot-password`, payload),
  resetPassword: (payload) => api.post(`auth/reset-password`, payload),
  verifyEmail: (payload) => api.post(`auth/verify-email`, payload),
};

import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';

// eslint-disable-next-line no-unused-vars
const createStore = (config) => {
  return configureStore({
    reducer: rootReducer,
  });
};

export default createStore;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'payouts';

const getOrgPayouts = createAsyncThunk(
  'payouts/getOrgPayouts',
  async (payload, { getState }) => {
    const user = getOr(null, ['auth', 'user'], getState());
    const response = await api.getOrgPayouts(user);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      payouts: [],
    },
    extraReducers: {
      [getOrgPayouts.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrgPayouts.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        payouts: getOr([], 'payload', action),
      }),
      [getOrgPayouts.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectOrgPayouts: (state) => getOr([], 'payouts', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getOrgPayouts,
  },
  selectors,
  reducer,
  name,
};

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'stats';

const getOrgStats = createAsyncThunk(
  'stats/getOrgStats',
  async (payload, { getState }) => {
    const user = getOr(null, ['auth', 'user'], getState());
    const response = await api.getOrgStats(user);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      stats: {},
    },
    extraReducers: {
      [getOrgStats.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrgStats.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getOrgStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectOrgStats: (state) => getOr({}, 'stats', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getOrgStats,
  },
  selectors,
  reducer,
  name,
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PropTypes from 'prop-types';

const Numbers = (props) => {
  const { inputRef, ...other } = props;
  //   const hexRegEx = /[0-9]/;
  const numberMask = createNumberMask({
    integerLimit: null,
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    decimalSymbol: '',
    prefix: '',
  });
  return (
    <MaskedInput
      {...other}
      guide={false}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
      showMask
    />
  );
};

Numbers.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default Numbers;

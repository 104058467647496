import React from 'react';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';
// import { ThemeProvider } from '@material-ui/styles';

import createStore from './src/state/createStore';
import theme from './src/gatsby-theme-material-ui-top-layout/theme';
import { AlertBar } from './src/components/custom';
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return (
    <Provider store={store}>
      <ThemeTopLayout theme={theme}>
        <>
          <AlertBar />
          {element}
        </>
      </ThemeTopLayout>
    </Provider>
  );
};

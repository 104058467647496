/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'org';

const getOrgDetails = createAsyncThunk('org/getOrgDetails', async (user) => {
  const response = await api.getOrgDetails(user);
  return response;
});

const updateAddress = createAsyncThunk(
  'org/updateAddress',
  async (payload, { getState }) => {
    const user = getOr(null, ['auth', 'user'], getState());
    const response = await api.updateAddress(payload, user);
    return response;
  }
);

const updateBankDetails = createAsyncThunk(
  'org/updateBankDetails',
  async (payload, { getState }) => {
    const user = getOr(null, ['auth', 'user'], getState());
    const response = await api.updateBankDetails(payload, user);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      org: {},
    },
    extraReducers: {
      [getOrgDetails.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getOrgDetails.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        org: getOr({}, 'payload', action),
      }),
      [getOrgDetails.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [updateAddress.fulfilled]: (state, action) => ({
        ...state,
        org: { ...state.org, ...getOr({}, 'payload', action) },
      }),
      [updateAddress.rejected]: (state, payload) => ({
        ...state,
        error: payload,
      }),
      [updateBankDetails.fulfilled]: (state, action) => ({
        ...state,
        org: { ...state.org, ...getOr({}, 'payload', action) },
      }),
      [updateBankDetails.rejected]: (state, payload) => ({
        ...state,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectOrgDetails: (state) => getOr({}, 'org', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getOrgDetails,
    updateAddress,
    updateBankDetails,
  },
  selectors,
  reducer,
  name,
};
